import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import getSymbolFromCurrency from 'currency-symbol-map';
import { format } from 'date-fns';

import CampaignSummaryCard from '@/components/cards/CampaignSummaryCard';
import ErrorBoxCard from '@/components/cards/ErrorBoxCard';
import TikTokPostTemplate from '@/components/utility/social-media-templates/TikTokPostTemplate';
import { tikTokAgeRangesString } from '@/constants/Advert';
import { getDiscountAmount } from '@/formatters/PriceFormatters';
import useSubscription from '@/hooks/account/useSubscription';
import usePricing from '@/hooks/pricing/usePricing';
import { MetaErrorValidationErrorModel } from '@/models/Meta';
import { TikTokAdsFormModel, TikTokAdsVideoModel } from '@/models/TikTok';

import Summary from '../../generic-ads-steps/Summary';
import PaymentOptions from '../../PaymentOptions';

const TikTokAdSummary = ({
  selectedTikTokVideo,
  errors,
}: {
  selectedTikTokVideo: TikTokAdsVideoModel | undefined;
  errors?: MetaErrorValidationErrorModel[];
}) => {
  const { t } = useTranslation();
  const { isSubscribed } = useSubscription();
  const { tikTokAds, tikTokAdsFull, isDataLoading } = usePricing();

  const { getValues } = useFormContext<TikTokAdsFormModel>();

  const summaryData = useMemo(() => {
    const formAd = getValues('ad');
    const formPriceLocale = getValues('priceLocale');

    return [
      {
        name: 'CAMPAIGNS-PAGE.GOAL',
        value: formAd.goal ? formAd.goal?.replace('-', ' ') : 'N/A',
      },
      {
        name: 'CAMPAIGNS-PAGE.START-DATE',
        value: `${format(new Date(formAd.startTime), 'dd/MM/yy')} at ${format(new Date(formAd.startTime), 'HH:mm b')}`,
      },
      {
        name: 'CAMPAIGNS-PAGE.END-DATE',
        value: `${format(new Date(formAd.endTime), 'dd/MM/yy')} at ${format(new Date(formAd.endTime), 'HH:mm b')}`,
      },
      {
        name: 'CAMPAIGNS-PAGE.BUDGET',
        value: formAd.budget ? `${getSymbolFromCurrency(formPriceLocale)}${formAd.budget}` : 'N/A',
      },
      {
        name: 'CAMPAIGNS-PAGE.AUDIENCE',
        value: `${t(formAd.targeting?.gender ?? '')}, ${formAd.targeting?.ageGroups.map((value) => tikTokAgeRangesString[value]).join(', ')}`,
      },
      {
        name: 'CAMPAIGNS-PAGE.LOCATIONS',
        value: formAd.targeting?.locations ? formAd.targeting?.locations.map((value) => value.name).join(', ') : '',
      },
      {
        name: 'CAMPAIGNS-PAGE.INTERESTS',
        value: formAd.targeting?.interests ? formAd.targeting?.interests.map((value) => value.name).join(', ') : '',
      },
      {
        name: 'CAMPAIGNS-PAGE.PLATFORM',
        value: formAd.platforms.join(', '),
      },
    ];
  }, [getValues, t]);

  return (
    <Summary>
      <div className="text-left flex-w50p w100p-lg-down">
        <h4>{t('SOCIAL-ADS.PREVIEW-YOUR-AD')}</h4>
        <p className="text-faded mt8 mb16">{t('SOCIAL-ADS.TIKTOK-PREVIEW-DESCRIPTION')}</p>
        {selectedTikTokVideo && <TikTokPostTemplate postDetails={selectedTikTokVideo} isOverlay={true} />}
      </div>
      <div className="text-left flex-w50p w100p-lg-down">
        <h4>{t('SOCIAL-ADS.CHECK-THE-DETAILS')}</h4>
        <p className="text-faded mt8 mb16">{t('SOCIAL-ADS.CHECK-THE-DETAILS-DESCRIPTION')}</p>
        {summaryData && <CampaignSummaryCard summary={summaryData} />}
        {errors && (
          <div className="mt20">
            <ErrorBoxCard errors={errors} />
          </div>
        )}
        {!isSubscribed && !isDataLoading && tikTokAds && (
          <div className="mt20">
            <PaymentOptions type="tiktok-ads" amount={tikTokAds.price} discount={getDiscountAmount(tikTokAdsFull)} />
          </div>
        )}
      </div>
    </Summary>
  );
};

export default TikTokAdSummary;
